import  React, { Fragment, Component,useState  } from "react"
import latinnouns from './nouns.json'
import Generate from './Generate'
import Select from 'react-select'
import './motto.css'


const App = () => {
  //  console.log (latinnouns.content);

  //Latinnouns = sort_by_key(Latinnouns, 'translation');

    var NounList=[];
  
    if (latinnouns) {
    latinnouns.forEach(function(element, index) {
    //    console.log(element);
        NounList.push({ label:element.translation , value: index })
       });
      }
    // Latinnouns.content.map(( content, index) => {
    //   NounList.push({ label:content.translation[index], value: index })
    //  // return <li key={`content_item_${index}`}>{data.item}</li>
    // })
  

    const [primary, setName] = useState(-1);
    const [showPrimary, setShowPrimary] = React.useState(false)
    const [secondary, setSecName] = useState(-1);
    const [showSecondary, setShowSecondary] = React.useState(false)
    

    let primaryText
    let secondaryText

    function sort_by_key(array, key)
{
  if (array) {
 return [].slice.call(array).sort(function(a, b)
 {
  var x = a[key]; var y = b[key];
  return ((x < y) ? -1 : ((x > y) ? 1 : 0));
 });
  }
}



    const handlePrimarySelection = e => {  
        setName(e.value);
        setShowPrimary(true);
    };

    const handleSecondarySelection = e => {  
        setSecName(e.value);
        setShowSecondary(true);
    };

      if (showPrimary===false) {
        primaryText="Choose your primary concept"
      } else {
        if (showSecondary===false){
          primaryText="Choose your secondary concept"
        } else{
        primaryText= `Your primary concept: ${NounList[primary].label.toUpperCase()}`
        }
      }

      if (showSecondary===false) {
        secondaryText="Choose your secondary concept"
      } else {
        secondaryText= `Your secondary concept: ${NounList[secondary].label.toUpperCase()}`
       
    }

  
    return (
        <Fragment>
        
          <div className='dropDowns'>
            <Select className='custom-select' options={ NounList } onChange={handlePrimarySelection} />
            
            {showPrimary ? <Select className='custom-select' options={ NounList } onChange={handleSecondarySelection}/> : ""}  
            
           
            </div>
            <h3>{primaryText}</h3>
            {showSecondary ? <h3>{secondaryText} </h3> : ""}
            {showSecondary ? <Generate first={primary} second={secondary}/>  : ""}
        </Fragment>
    )
    }

  
  export default App
  