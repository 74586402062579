import  React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import Head from '../components/head'
import MottoGen from './MottoGen/App'
import FeatureImg from "../images/panteon-motto-full.jpg"

// markup
const MottoGenPage = () => {
  return (
   <Layout >
      <Head title='Latin Motto Generator' description='Make your own Latin motto without any prior knowledge of the Latin language. Just pick your core concepts and a list of mottos will be automatically generated.'/>
     <h1>Latin Motto Generator</h1>
     <img className={"featureImageFloat"} src={FeatureImg} alt="Rome"/>
     <p><i><b>'Dr Johnson said, the inscription should have been in Latin, as every thing intended to be universal and permanent, should be. '</b></i></p>
<p>
James Boswell,<br/> <i>The Journal of a Tour to the Hebrides <br/> with Samuel Johnson, LL.D.</i>
</p>
<p>
Short Latin sayings have been traditionally used in heraldry as 
slogans and mottos (life mottos, family mottos, state mottos, 
senior class mottos etc.). Hence <i>Semper fidelis</i>, the Marine Corps 
motto, <i>Carpe diem,</i> a life motto for adventurous types, and <i>Memento mori</i>, 
a stern reminder to us all. </p>
<p>
If you desire to make your own Latin motto, 
but don't want to go back to school and take a Latin course, 
don't feel like learning Latin independently, 
and have no intention to employ professional 
translation services -- this page is specifically designed to offer some help.
Or you can use our list of <Link to = '/LatinQuotes/LatQuotes_a'>Latin quotes, mottos and phrases with translations.</Link><br></br>
</p>
     <MottoGen/>
     <p>
<hr></hr>   
<p>Although this Latin motto generator is primarily intended for 
educational purpuses,  you are welcome to become inspired by some cool 
life mottos you create here. Or you just may want to spice up your 
FaceBook profile with a Latin slogan. You will be automatically regarded 
as a tasteful person of considerable learning. 
You may even be considered an embodiment of wisdom. All that without ever graduating from highschool or taking a Latin class. A motto can also greatly improve the looks of your family tree. Whether you live by it or not, that's up to you :) It is never too late for any family to adopt a slogan!
</p>

		You will quickly recognize the patterns used in this 
    generator - they are the same as in many popular mottos. Simply choose appropriate English words and click on &quot;Create Motto&quot;. The script will take care of arranging the resulting Latin words in the right order and putting them in correct grammatical forms. For clarity's sake all mottoes are shown in lowercase, however if you want to change them to all caps it is a custom to replace U's with V's, as in old inscriptions. Ancient Romans did not distinguish between these two letters. They also did not  differentiate between lower an upper case letters. So, instead of DEUS ET&nbsp;PATRIA you should preferably have DEVS&nbsp;ET&nbsp;PATRIA. These are, of course, minor points. Just something to consider if you indeed decide to use these mottos anywhere. Or perhaps you are here simply wanting to know a Latin word for 'fire', 'love' or 'truth'? That's cool too, just use the nominatives in the very first script. Enjoy!   <br/>

These scripts will not render anything complex,
 but there is plenty of depth and pathos in the results you might get. 
 You might even uncover some universal maxims with deep 
 meaning worthy of being adopted as personal mottos and summarize 
 your thoughts about life. </p>
 
 <p>Be sure to exercise extreme caution while 
 attempting to use computer generated text for tattoos, 
 logos, religious jewelry, wedding rigns, gravestones etc. 
 (in case if you were wondering why learn Latin at all, 
 it is a must for any tattoo artist). Always remember that 
 if the sentence you are trying to translate makes little sense
in English it is not likely to be meaningful in Latin! 
If you are looking for some aphorism or just a phrase with a deep meaning
 there are plenty of pages on this site that can offer some help.<br/>
</p>
   </Layout>
  )
}

export default MottoGenPage
